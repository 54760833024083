import React from "react"

import Layout from "../components/templates/layout"
import SEO from "../components/seo"

import HeroIndex from "../components/organisms/heroIndex"
import HeroOQueOferecemos from "../components/organisms/heros/oQueOferecemos"
import HeroTragaSuasIdeias from "../components/organisms/heros/tragaSuasIdeias"
import HeroSobreNos from "../components/organisms/heros/sobreNos"
import HeroCallToAction from "../components/organisms/heros/callToAction"

const IndexPage = ({ location }) => (
  <Layout fluid={true} lang="en" location={location}>
    <SEO title="Home" />

    <HeroIndex lang="en" />
    <HeroOQueOferecemos lang="en" />
    <HeroTragaSuasIdeias lang="en" />
    <HeroSobreNos lang="en" />
    <HeroCallToAction lang="en" />
  </Layout>
)

export default IndexPage
